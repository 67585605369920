<template>
  <div id="app">
    <Popup/>
    <router-view></router-view>
  </div>
  
</template>
  
  <script>
  // import { ref } from "vue";
  
  // Register websocket event listeners
  import Popup from '@/components/Popup.vue'
  import './socketListeners'
  
  export default {
    name: 'App',
    components: {
      Popup,
    },
    setup() {
      // const hp = ref(0);
      // setInterval(() => (hp.value = Math.floor(Math.random() * 35)),2000);
      
      // return {hp}
      
    },
    data: function() {
      return {
      }
    },
    methods: {
      pickRandomBackgroundOnDOMLoad() {
      var backgrounds = ['banner_bg_7_mobile.webp', 'banner_bg_8_mobile.webp', 'banner_bg_9_mobile.webp', 'banner_bg_10_mobile.webp', 'banner_bg_11_mobile.webp', 'banner_bg_12_mobile.webp']
      if (window.innerWidth <= 768) {
        document.body.style.backgroundImage = 'url(' + require('@/assets/backgrounds/'+backgrounds[Math.floor(Math.random() * backgrounds.length)]) + ')'
      } else {
        var desktopBackgrounds = ['banner_bg_1.webp', 'banner_bg_2.webp', 'banner_bg_6.webp']
        document.body.style.backgroundImage = 'url(' + require('@/assets/backgrounds/'+desktopBackgrounds[Math.floor(Math.random() * desktopBackgrounds.length)]) + ')'
      }
    },
    },
  

    // before the DOM is mounted
    beforeMount: function() {
    },

    
    created: function() {

      
    },
  
    // do things on first load of the DOM
    mounted: function() {
      this.pickRandomBackgroundOnDOMLoad()
    },
  
    // do things for when the DOM is already loaded
    updated: function() {
      
      
    },
  }
  
  
  
  
  
  
  
  
  </script>
  
  <style>
  :root {
    --game-banner-height: 2.5em;
    --game-footer-height: 4em;
  }

  body {
    background-color: rgb(42, 42, 42);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-attachment: fixed;
    overscroll-behavior: contain; /**Disable Refreshing on screen pull-down for mobile */
  }

  #app {
    font-family: 'Metal Mania'; /* Set the global font to Metal Mania */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: grey;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  </style>
  
